import React from 'react';
import { Box, keyframes, style } from '@mui/system';
import { styled } from '@mui/material';
import { useResponsive } from './hooks/use-responsive';

// Define the keyframes for the opacity animation
const pulseAnimation = keyframes`
from {
	stroke-dashoffset: 0;
}
to {
	stroke-dashoffset: 9;
}
`;

const StyledDash = styled('path')`
	stroke-dasharray: 5;
  stroke-dashoffset: 5;
	animation: ${pulseAnimation} 1s linear infinite;
`;

const AnimatedPath: React.FC = () => {
	const smDown = useResponsive("down", "sm");

  return (
    <Box
      sx={{
        zIndex: 0,
        position: 'absolute',
        left: smDown? '60%' : '50%',
        top: 0,
        opacity: 0.5,
        transform: 'translateX(-50%)', // Center the SVG horizontally
      }}
    >
      <svg width="92" height="208" viewBox="0 0 92 208" fill="none" xmlns="http://www.w3.org/2000/svg">
        <StyledDash
          d="M91 205.861C91 205.861 74.4863 208.766 59.2112 188.037C43.936 167.309 33.1933 130.78 34.8535 97.5475C36.0772 73.0509 37.3305 35.1645 26.1838 15.9697C15.037 -3.22503 1 2.9447 1 2.9447"
          stroke="#6188FF"
          strokeWidth="3"
          strokeDasharray="5 5"
          
        />
      </svg>
    </Box>
  );
};

export default AnimatedPath;
