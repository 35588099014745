import React from 'react';

const Cursor: React.FC = ({sx}) => {
  return (
<svg
	 viewBox="0 0 50 50" enable-background="new 0 0 50 50" {...sx}>
<path d="M35.18,38.963c0.021-0.08,0.031-0.163,0.031-0.249v-8.731c0-1.468-1.216-2.661-2.711-2.661h-0.191
	c-0.28,0-0.552,0.041-0.808,0.118c-0.337-1.093-1.373-1.891-2.595-1.891h-0.191c-0.275,0-0.541,0.04-0.792,0.114
	c-0.321-1.119-1.369-1.941-2.61-1.941h-0.191c-0.238,0-0.47,0.029-0.69,0.086V19.35c0-1.468-1.217-2.661-2.712-2.661h-0.191
	c-1.495,0-2.711,1.193-2.711,2.661v12.339l-1.112-1.08c-1.021-0.994-2.796-0.991-3.812-0.003l-0.138,0.133
	c-0.519,0.504-0.805,1.176-0.805,1.893s0.286,1.389,0.762,1.849l5.251,5.763c0.446,0.519,1.1,0.816,1.792,0.816h12.087
	C34.055,41.059,35.057,40.149,35.18,38.963z M20.46,38.916l-5.313-5.828c-0.127-0.123-0.197-0.286-0.197-0.457
	s0.07-0.334,0.195-0.455l0.139-0.134c0.135-0.132,0.317-0.204,0.513-0.204s0.378,0.072,0.514,0.205l2.81,2.728
	c0.288,0.279,0.715,0.359,1.086,0.203c0.37-0.156,0.61-0.52,0.61-0.921V19.35c0-0.364,0.319-0.661,0.711-0.661h0.191
	c0.393,0,0.712,0.297,0.712,0.661v6.778c0,0.521,0.401,0.956,0.922,0.997c0.518,0.042,0.984-0.325,1.065-0.841
	c0.051-0.32,0.354-0.563,0.703-0.563h0.191c0.392,0,0.711,0.297,0.711,0.661v1.573c0,0.521,0.401,0.956,0.921,0.997
	c0.531,0.04,0.985-0.324,1.066-0.84c0.052-0.321,0.354-0.564,0.704-0.564h0.191c0.392,0,0.711,0.297,0.711,0.662v1.518
	c0,0.521,0.401,0.956,0.921,0.997c0.533,0.037,0.985-0.324,1.066-0.84c0.052-0.321,0.354-0.564,0.704-0.564H32.5
	c0.392,0,0.711,0.297,0.711,0.661v8.57c-0.012,0.063-0.019,0.129-0.019,0.194c0,0.172-0.157,0.312-0.351,0.312H20.755
	C20.646,39.059,20.545,39.015,20.46,38.916z"/>
<path d="M11.211,18.313c0,0.553,0.447,1,1,1h3.347c0.553,0,1-0.447,1-1s-0.447-1-1-1h-3.347
	C11.658,17.313,11.211,17.76,11.211,18.313z"/>
<path d="M22.658,13.308v-3.249c0-0.553-0.447-1-1-1s-1,0.447-1,1v3.249c0,0.553,0.447,1,1,1S22.658,13.86,22.658,13.308z"/>
<path d="M17.932,16.013c0.007,0,0.015,0.001,0.02,0c0.553,0,1-0.447,1-1c0-0.342-0.171-0.644-0.433-0.824l-2.257-2.19
	c-0.396-0.385-1.029-0.375-1.414,0.021s-0.375,1.029,0.021,1.414l2.366,2.297C17.43,15.919,17.681,16.013,17.932,16.013z"/>
<path d="M26.675,18.313c0,0.553,0.447,1,1,1h3.347c0.553,0,1-0.447,1-1s-0.447-1-1-1h-3.347
	C27.122,17.313,26.675,17.76,26.675,18.313z"/>
<path d="M26.971,11.998l-2.366,2.297c-0.396,0.385-0.406,1.018-0.021,1.414c0.196,0.202,0.457,0.304,0.718,0.304
	c0.251,0,0.502-0.094,0.696-0.282l2.367-2.298c0.396-0.385,0.404-1.018,0.02-1.414C28.001,11.624,27.367,11.613,26.971,11.998z"/>
</svg>
	);
}

export default Cursor;